
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$soul-path-readings-primary: mat.define-palette(mat.$indigo-palette);
$soul-path-readings-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$soul-path-readings-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$soul-path-readings-theme: mat.define-light-theme((
  color: (
    primary: $soul-path-readings-primary,
    accent: $soul-path-readings-accent,
    warn: $soul-path-readings-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($soul-path-readings-theme);

@import '../var';

.loading-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 600rem;
    width: 100%;
}

.loading {
    margin: 0 auto;
}

.loading-title {
    color: $beige;
    font-size: $fs-16;
    font-weight: $fw-700;
    letter-spacing: $m-16;
    margin-bottom: $m-24;
}

.loading-progress-bar {
    width: 100%;
    height: 6rem;
    opacity: 0.5;
    background: linear-gradient(90deg, #202329 0%, #000 100%);

    .progress-bar-inner {
        width: 0;
        height: 100%;
        background: linear-gradient(270deg, #F1AF80 0%, rgba(224, 146, 95, 0.00) 100%);
        animation: loading 2s linear infinite;

        @keyframes loading {
            to {
                width: 100%;
            }
        }
    }
}

.payment-loading {
    .loading-wrapper {
        margin-top: 50rem;
    }
}
