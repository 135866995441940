@import "../var";

.row-container {
    display: flex;
    gap: 16rem;
}

.input-container {
    position: relative;
    width: 100%;

    &.counter-container,
    &.question-container {
        display: flex;
        flex: 1;

        .limit-info {
            position: absolute;
            right: 30rem;
            bottom: 10rem;
            color: $beige;
            font-size: 12rem;
        }
    }

    .input-field {
        width: 100%;
        height: 50rem;
        padding: $m-12 $m-24;
        border-radius: 7rem;
        border: 1px solid $gold;
        background: #110B2E;
        box-shadow: 0 0 15px 0 #0C0727 inset;
        margin-bottom: $m-16;
        position: relative;

        &.password-input {
            padding-right: 50rem;
        }

        @media screen and (max-height: $mobile) {
            height: 35rem;
            padding: 9rem $m-16;
            margin-bottom: $m-12;
        }

        input {
            width: 100%;
            height: 100%;
            background: transparent;
            border: none;
            outline: none !important;
            color: $beige;
            font-size: $fs-20;
            letter-spacing: -0.4rem;

            @media screen and (max-height: $mobile) {
                font-size: 12rem;
            }
        }
    }

    .error {
        color: $red;
        font-size: $fs-16;
        margin-bottom: $m-16;
        text-align: left;

        @media screen and (max-height: $mobile) {
            font-size: 10rem;
            margin-bottom: 10rem;
        }

        a {
            color: inherit;
        }
    }

    .eye {
        position: absolute;
        right: 10rem;
        top: 50%;
        transform: translateY(-50%);
        width: $m-36;
        height: $m-36;

        @media screen and (max-height: $mobile) {
            width: 20rem;
            height: 20rem;
        }
    }
}

.cosmic-profile-input.date-picker {
    position: relative;
    flex: none;

    input {
        padding: 0 32rem !important;
    }

    input {
        padding: 0 32rem !important;
    }

    mat-datepicker-toggle {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        outline: none !important;
        width: 36px;
        height: 36px;
        .mat-icon-button {
            line-height: unset;
            border-radius: 0;
            width: 100%;
            height: 100%;
        }

        .mat-ripple.mat-button-ripple.mat-button-ripple-round,
        .mat-button-focus-overlay {
            display: none !important;
        }


        button {
            color: $beige;
        }
    }
}

.mat-datepicker-popup {
    background: #464141;
    color: $beige;

    .mat-button-disabled {
        opacity: .4;
    }


    .mat-calendar-body-cell {
        border-color: $gold !important;

        &.mat-calendar-body-active {
            color: $gold;
        }

        &.mat-calendar-body-disabled {
            opacity: .4;
        }

        .mat-calendar-body-selected {
            background: $gold;
            color: $black !important;
        }
    }

    button {
        color: $beige;
    }
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: $beige;
    color: $black !important;
}

.btn {
    width: 100%;
    height: 50rem;
    border-radius: 7rem;
    border: 1px solid $gold;
    background: #FDF6EF;
    padding: $m-12 $m-24;
    color: $black;
    font-size: $fs-20;
    font-weight: $fw-600;
    letter-spacing: -0.4rem;
    margin-bottom: $m-16;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    // text-transform: uppercase;

    @media screen and (max-height: $mobile) {
        height: 35rem;
        font-size: 12rem;
        padding: 0;
        margin-bottom: 0;
    }

    &.gold {
        border-radius: 7rem;
        border: 1px solid $brown;
        background: $gold;
    }

    .icon {
        width: 32rem;
        height: 32rem;
        margin-right: $m-24;

        @media screen and (max-height: $mobile) {
            width: 22rem;
            height: 22rem;
            margin-right: 16rem;
        }
    }

    &.transparent {
        border-radius: 7rem;
        border: 1px solid #F1AF80;
        background: #1F1F1F;
        box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.25) inset;
        color: $white;
        // font-size: $fs-16;
    }

    &:disabled {
        filter: contrast(.5);
        cursor: default;
    }
}

dp-date-picker {
    .dp-picker-input {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: none;
        border-radius: 7rem;
        background: #464141;
        padding: 24rem;
        height: 56rem;
        margin-right: 8rem;
        color: $beige;
        font-size: 20rem;
        font-weight: 600;
        line-height: 1.2;
    }
}

textarea,
input {
    position: relative;

    &[maxlength] {
        &:after {
            content: attr(data-remainder);
            position: absolute;
            right: 20rem;
            bottom: 5rem;
            color: white;
        }
    }

}

.pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

@keyframes ellipsis-profile {
    to {
        width: 12px;
    }
}

@-webkit-keyframes ellipsis-profile {
    to {
        width: 12px;
    }
}

@keyframes ellipsis {
    to {
        width: 24rem;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 24rem;
    }
}
