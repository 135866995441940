@import "../var";

.swiper {

    .swiper-button-next,
    .swiper-button-prev {
        height: 100%;
        top: 0;
        margin: 0;
        padding: 0 40rem;
        width: calc(20% - 120rem);

        @media screen and (max-width: $mobile) {
            width: calc(25% - 120rem);
        }

        &:hover {
            &:after {
                transition: .2s ease-in-out;
                transform: scale(1.2);
            }
        }

        &::before {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            height: 100%;
            border-radius: 7rem;
            width: 163rem;
            z-index: 1;
        }

        &::after {
            color: #F1AF80;
            z-index: 22;
        }
    }

    .swiper-button-prev {
        left: 0;
        background: linear-gradient(-90deg, rgba(32, 35, 41, 0.00) 0%, #202329 81.77%);
    }

    .swiper-button-next {
        right: 0;
        background: linear-gradient(90deg, rgba(32, 35, 41, 0.00) 0%, #202329 81.77%);
    }
}
