@import 'swiper/swiper-bundle.min.css';
@import './assets/swiper.scss';
@import './assets/reset.scss';
@import './assets/fonts/fonts.scss';
@import './assets/elements.scss';
@import './var.scss';

html {
    font-size: 1px;

    @media (max-width: 1920px) and (max-height: 963px) {
        font-size: 0.95px;
    }

    @media screen and (max-width: 1650px) {
        font-size: 0.9px;
    }

    @media screen and (max-width: 1366px) {
        font-size: 0.85px;
    }

    @media screen and (max-width: $tablet-h) {
        font-size: 0.6px;
    }

    @media (-webkit-min-device-pixel-ratio: 1.05) {
        font-size: 0.65px;
    }

    @media screen and (max-width: $tablet-h) {
        overflow: auto;
        min-width: 1024px;
        height: 100%;
        min-height: 768px !important;
        // max-height: 768px !important;
    }
}

* {
    user-select: none;
}

body {
    font-family: $messiri;
    font-size: $m-16;
    font-weight: $fw-400;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    background: linear-gradient(0deg, rgba(33, 38, 51, 0.50) 0%, rgba(33, 38, 51, 0.50) 100%), #1F1F1F;

    // width: 1366px;
    // height: 768px;

    @media screen and (max-width: $tablet-h) {
        max-height: 100%;
    }

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    &::-webkit-scrollbar-track {
        width: 4px;
        height: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: $beige;
        border-radius: 4px;
    }
}

#chartContainer {
    display: none;
}

.astro-block#chartContainer {
    svg {
        width: 100%;
        height: auto;
    }
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.flex-1 {
    flex: 1;
}

.flex-wrap {
    flex-wrap: wrap;
}

img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.uppercase {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

.text-center {
    text-align: center;
}

button {
    cursor: pointer;
}


.astro-profile {
    .personal-summary-info {
        .summary-title {
            display: block;
            width: 100%;
            font-weight: $fw-800;
        }
    }
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
    img {
      clip-path: inset(1px)
    }
  }