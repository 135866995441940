// font-family
$cherish: 'Cherish', cursive;
$messiri: 'El Messiri', sans-serif;

//font-weight
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;

//font-size
$fs-16: 16rem;
$fs-20: 20rem;
$fs-24: 24rem;
$fs-28: 28rem;
$fs-32: 32rem;
$fs-36: 36rem;
$fs-48: 48rem;
$fs-60: 60rem;
$fs-128: 128rem;

//colors 
$beige: #F8E2D2;
$gold: #F1AF80;
$brown: #C7895D;
$black: #202329;
$red: #FF7070;
$white: #fff;

//margins
$m-12: 12rem;
$m-16: 16rem;
$m-24: 24rem;
$m-32: 32rem;
$m-36: 36rem;
$m-42: 42rem;
$m-48: 48rem;


$tablet-h: 1199px;

$mobile: 1px;

.mobile {
    display: none !important;
}

.mt-auto {
    margin-top: auto;
}

.mx-auto {
    margin: 0 auto;
}

.mt-24 {
    margin-top: $m-24;

    @media screen and (max-height: $mobile) {
        margin-top: 0;
    }
}

.mt-0 {
    margin-top: 0;
}

.mb-42 {
    margin-bottom: $m-42 !important;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}
